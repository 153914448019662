<template>
  <div class="home">
    <div class="flex">
      <div class="home_banner">
        <el-carousel height="498px" indicator-position="none" arrow="hover">
          <!-- <el-carousel-item
            v-for="(item, index) in bannerImg"
            :key="item.id"
            :class="bg_banner[index]"
          >
            <img :src="item.img" alt="" />
          </el-carousel-item> -->
          <el-carousel-item
            v-for="(item) in bannerImg"
            :key="item.id"
          >
            <img :src="item.url" alt="" @click="toLink(item.url2)" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="home_code">
        <div>
          <div>
            <img src="~@/assets/wanli.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="home_bottom">
     

      <div class="foot">

<p> 


 版权所有 上海万历报关有限公司  <a href="http://beian.miit.gov.cn/" target="_blank">  泸ICP备12049925号 </a>联系电话：021-50462794
传真：021—50643637
邮箱：wanlish@wanlish.com <img
class="jing"
          src="~@/assets/uploadfiles/2016121501144898.jpg"
          alt=""
        /></p>
        <br />
        <router-link to="/companyProfile"> 走进万历</router-link>|

<router-link to="/newsCenter"> 新闻中心</router-link>|

<router-link to="/customsInspection"> 万历咨询与服务</router-link>|

<router-link to="/onlineConsulting"> 联系我们</router-link>


</div>
<br />
<div class="totop"> <a href="/"> <img class="lazy"  src="~@/assets/totop.png" style="display: inline;"></a> </div>
    </div>
  </div>
</template>

<script>
import { getBanner } from "@/api/index";

export default {
  name: "HomeView",
  data() {
    return {
      current_page: 1, //当前页
      pageSize: 10, //当前页条数
      //首页轮播图列表
      bannerImg: [
        {
          id: 1,
          img: "~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031114412212500.png",
          bgimg:
            "~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031016431884375.jpg",
        },
        {
          id: 2,
          img: "~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031114535578125.png",
          bgimg:
            "~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031114522882812.jpg",
        },
        {
          id: 3,
          img: "~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031114572700000.png",
          bgimg:
            "~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031114554462500.jpg",
        },
      ],
      //轮播图背景设置
      bg_banner: ["bg_banner1", "bg_banner2", "bg_banner3"],
    };
  },
  created() {
    this.toGetBanner();
  },
  methods: {
    //获取banner数据
    toGetBanner() {
      getBanner(this.current_page, this.pageSize).then((res) => {
        console.log(res, "获取banner数据");
        this.bannerImg = res.data.list
      });
    },
    toLink(url){
      window.open(url,"_blank");
    }
  },
};
</script>
<style scoped lang="scss">
.foot{
	height: 58px;
	width: 100%;
	background-color: #6e9ac1;
	text-align: center;
	font-size: 14px;
	color: #fff;
	padding: 42px 0px;
	overflow: hidden;
  a:link,a:visited,a:hover{
    color: #fff;
    padding: 0 5px 0 5px;
  }
}
.foot p a{
	color: #333333;
	display: inline-block;
	margin: 8px 12px;
}
.foot img.jing{
  width: 15px;
  vertical-align: middle;
} 
.totop{
	width: 100%;
	height: 81px;
	text-align: center;
  opacity: 0.3;
  background-color:  #6d99c0;
}
.totop img{
	margin: 18px auto;
}
.home {
  width: 100%;
  background-image: url(~@/assets/uploadfiles/201403061045053.jpg);
  background-repeat: repeat-x;
  background-position: 100% -150px;
  .flex {
    width: 76%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    // background-color: #d45805;
    height: 482px;
  }
  .home_code {
    width: 10%;
    margin-left: 15px;
    > div {
      margin-bottom: 20px;
      text-align: center;
      color: #3e8ef7;
      font-size: 12px;
    }
    img {
      width: 176%;
    }
  }
  .home_banner {
    width: 80%;
    height: auto;
    img{
      width: 100%;
      height: 500px;
      border-radius: 15px;
    }
    // margin: 2% auto;
    .bg_banner1 {
      background-image: url(~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031016431884375.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      > img {
        margin: 50px;
      }
    }
    .bg_banner2 {
      background-image: url(~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031114522882812.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      > img {
        margin: 50px;
      }
    }
    .bg_banner3 {
      background-image: url(~@/assets/styleimages/flashimages/flashnet_ImgPic_2014031114554462500.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      > img {
        margin: 15% 50%;
      }
    }
  } 
}

body .home_bottom {
    width: 100%;
    position: absolute;
    height: 22vh;
    background: #fff;
    margin:  auto;
    font-size: 12px;
    color: #000;
    // background-color: blueviolet;
    > div:first-child {
      > span:first-child {
        margin-left: 10px;
      }
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    img {
      width: 5%;
    }
  }
</style>