import axios from "axios";
axios.defaults.withCredentials = true;// 允许跨域携带cookie

// 使用自定义配置新建一个 axios 实例
const service = axios.create({
    // 基础的请求地址
    // baseURL: "http://43.139.203.117:8080",
    baseURL: "https://www.wanlish.com/houduan/",
    // baseURL: "/api",


    // 设置超时时间 5s
    timeout: 15000,
});

export default service;
