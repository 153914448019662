import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/enterWanlish',
    name: 'enterWanlish',
    redirect: '/companyProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EnterWanlish.vue'),
    children: [
      {
        path: '/companyProfile',
        name: 'companyProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wanlish/CompanyProfile.vue')
      },
      {
        path: '/wanliProgress',
        name: 'wanliProgress',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wanlish/WanliProgress.vue')
      },
      {
        path: '/honorsQualifica',
        name: 'honorsQualifica',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wanlish/HonorsQualifica.vue')
      },
      {
        path: '/wanliCulture',
        name: 'wanliCulture',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wanlish/WanliCulture.vue')
      },
      {
        path: '/wanliMonograph',
        name: 'wanliMonograph',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wanlish/WanliMonograph.vue')
      },
      {
        path: '/labourUnion',
        name: 'labourUnion',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wanlish/LabourUnion.vue')
      },
      {
        path: '/enterpriseAlbum',
        name: 'enterpriseAlbum',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wanlish/EnterpriseAlbum.vue')
      },
      {
        path: '/enterpriseVideo',
        name: 'enterpriseVideo',
        component: () => import(/* webpackChunkName: "about" */ '../views/Wanlish/EnterpriseVideo.vue')
      },
    ]
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsCenter.vue')
  },
  {
    path: '/consultService',
    name: 'consultService',
    redirect: '/customsInspection',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConsultService.vue'),
    children: [
      {
        path: '/customsInspection',
        name: 'customsInspection',
        component: () => import(/* webpackChunkName: "about" */ '../views/Service/CustomsInspection.vue')
      },
      {
        path: '/goodsServices',
        name: 'goodsServices',
        component: () => import(/* webpackChunkName: "about" */ '../views/Service/GoodsServices.vue')
      },
      {
        path: '/enterpriseServices',
        name: 'enterpriseServices',
        component: () => import(/* webpackChunkName: "about" */ '../views/Service/EnterpriseServices.vue')
      },
      {
        path: '/solutionServices',
        name: 'solutionServices',
        component: () => import(/* webpackChunkName: "about" */ '../views/Service/SolutionServices.vue')
      },
      {
        path: '/knowledgeSharing',
        name: 'knowledgeSharing',
        component: () => import(/* webpackChunkName: "about" */ '../views/Service/KnowledgeSharing.vue')
      },
    ]
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    redirect: '/onlineConsulting',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue'),
    children: [
      {
        path: '/onlineConsulting',
        name: 'onlineConsulting',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contact/OnlineConsulting.vue')
      },
      {
        path: '/geographicalPosition',
        name: 'geographicalPosition',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contact/GeographicalPosition.vue')
      },
    ]
  },
  
]

const router = new VueRouter({
  routes
})
// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
