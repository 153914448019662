import request from '@/utils/request'
//获取bannner
export function getBanner(page,pageSize) {
    return request({
        url: `/banner/getBanner/${page}/${pageSize}`,
        method: 'post',
    })
}
//提交客户列表
export function addCostomer(data) {
    return request({
        url: '/costomer/addCostomer',
        method: 'post',
        data
    })
}
//获取文章
export function getArticle(data,page,pageSize) {
    return request({
        url: `/article/getArticle/${page}/${pageSize}`,
        method: 'post',
        data
    })
}