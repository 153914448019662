<template>
  <div id="app">
    <HeadInfo></HeadInfo>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>
<script>
import HeadInfo from "@/components/HeadInfo.vue";

export default {
  components: {
    HeadInfo,
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
#app,
body,
html {
  width: 100%;
}
body{
  height: 100vh;
}

// body{
//   overflow: hidden;
//   font-family: "微软雅黑";
//   margin: 0;
//   padding: 0;
//   position: relative;
//   width: auto;
//   height:auto;
//   background:url(~@/assets/uploadfiles/201403061045053.jpg) no-repeat;
//   background-position: center;
//   background-size: 100%;
// }
// #app,body,html{
//   // height: 100%;
//   // width: 100%;
//   // background-color: aqua;
//   // background-image: url(~@/assets/uploadfiles/201403061045053.jpg);
// // background-size: cover;
//   // background-repeat: no-repeat;
//   // background-position: center center;
// }
// #app{
//   // background-image: url(~@/assets/uploadfiles/201403061045053.jpg);
// background-color: aquamarine;
// width: 100%;
// height: 100%;
// }
// #app {
// font-family: Avenir, Helvetica, Arial, sans-serif;
// -webkit-font-smoothing: antialiased;
// -moz-osx-font-smoothing: grayscale;
// text-align: center;
// color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
